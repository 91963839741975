import {
  wrapApiError,
  handleError,
  getErrorMessage,
} from "./../utils/errorHandler";
import { CompassCompany } from "./../utils/api";
import { Call, Connection, EventType, Queue } from "compass.js";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";
import { getDateStr } from "src/utils/dateTime";
import { parse } from "papaparse";
import {
  getQueueStatistics,
  isQueueVisible,
  QueueDetailsStatistics,
} from "src/utils/queue";
import { Subscription } from "rxjs/internal/Subscription";
import { compassDebouncePipe } from "src/utils";
import { Subject } from "rxjs";
import axios from "axios";

const getStatsCallId = (callId: Call["id"], queueId: Queue["id"]) => {
  return `${callId}:${queueId}`;
};

let compassQueuesSubscription: Subscription | null;
let compassUsersSubscription: Subscription | null;
let compassCallsSubscription: Subscription | null;

// Historical data update intervals in seconds
// https://gitlab.iperitydev.com/compass/panel/-/issues/84
const refreshStatisticsIntervals = [120, 240, 480, 960];
let refreshCurrentStatisticsInterval: NodeJS.Timer | null;
let cleanupYesterdayStatisticsInterval: NodeJS.Timer | null;
let refreshStatisticsTimeout: NodeJS.Timeout | null;

type QueueTodayStatistics = {
  callsCount: number;
  callsAnswered: number;
  waitDurations: number[];
};

type CallStatistics = {
  callId: Call["id"];
  queueId: Queue["id"];
  answered: boolean;
  waitDuration: number;
  timeStarted: number;
};

const getTodayStatistics = (callsStatistics: {
  [key: string]: CallStatistics;
}): { [key: string]: QueueTodayStatistics } => {
  const statistics: { [key: string]: QueueTodayStatistics } = {};
  const callsByQueue: { [key: string]: CallStatistics[] } = {};
  Object.values(callsStatistics).forEach((call) => {
    callsByQueue[call.queueId] = [call, ...(callsByQueue[call.queueId] || [])];
  });
  Object.keys(callsByQueue).forEach((queueId) => {
    const queueCalls = callsByQueue[queueId];
    const waitDurations = queueCalls.map(({ waitDuration }) => waitDuration);
    statistics[queueId] = {
      callsCount: queueCalls.length,
      callsAnswered: queueCalls.filter(({ answered }) => answered).length,
      waitDurations,
    };
  });
  return statistics;
};

type DataState = {
  readonly todayStatistics: {
    [key: string]: QueueTodayStatistics;
  };
  readonly calls: {
    [key: string]: CallStatistics;
  };
  readonly realtimeCalls: {
    [key: string]: CallStatistics;
  };
  readonly currentStatistics: { [key: string]: QueueDetailsStatistics };
};

export const setup = createAsyncThunk<
  void,
  {
    connection: Connection;
    companyId: CompassCompany["entityId"];
    server: string;
  },
  { state: RootState }
>("data/setup", (params, { dispatch, getState }) => {
  dispatch(
    updateCurrentStatistics(Object.values(params.connection.model.queues))
  );
  const updateAllCurrentStatistics$ = new Subject();
  updateAllCurrentStatistics$.pipe(compassDebouncePipe(1000)).subscribe(() => {
    dispatch(
      updateCurrentStatistics(Object.values(params.connection.model.queues))
    );
  });
  // Update current statistics every second for active queues
  refreshCurrentStatisticsInterval = setInterval(() => {
    Object.values(params.connection.model.queues).forEach((queue) => {
      const settings = getState().settings.queuesSettings;
      if (queue.getCalls().length && isQueueVisible(queue.id, settings)) {
        dispatch(dataSlice.actions.updateQueueCurrentStatistics(queue));
      }
    });
  }, 1000);

  // Cleanup statistics on new day
  let date = getDateStr(new Date());
  cleanupYesterdayStatisticsInterval = setInterval(() => {
    if (date !== getDateStr(new Date())) {
      dispatch(dataSlice.actions.cleanupYesterdayStatistics());
    }
  }, 60000);

  // Refresh historical statistics
  dispatch(updateTodayStatistics(params));
  const currentRefreshStatisticsIntervals = [...refreshStatisticsIntervals];
  const refreshStatistics = () => {
    if (!currentRefreshStatisticsIntervals.length) {
      return;
    }
    refreshStatisticsTimeout = setTimeout(() => {
      dispatch(updateTodayStatistics(params));
      refreshStatisticsTimeout = null;
      refreshStatistics();
    }, currentRefreshStatisticsIntervals.shift()! * 1000);
  };
  refreshStatistics();

  // Update statistics for compass events
  compassUsersSubscription = params.connection.model.usersObservable.subscribe(
    () => {
      updateAllCurrentStatistics$.next();
    }
  );
  compassCallsSubscription = params.connection.model.queuesObservable.subscribe(
    () => {
      updateAllCurrentStatistics$.next();
    }
  );
  compassQueuesSubscription = params.connection.model.queuesObservable
    .pipe(compassDebouncePipe(500))
    .subscribe(
      ({
        eventType,
        data,
        emitter,
      }: {
        eventType: EventType;
        data: any;
        emitter: any;
      }) => {
        if (eventType === EventType.CallRemoved) {
          const answered = !!Object.values(params.connection.model.users).find(
            (user) => !!user.getCalls().find((call) => data.call.id === call.id)
          );
          dispatch(
            addRealtimeCall({
              call: data.call,
              queueId: emitter.id,
              answered,
            })
          );
        }
        updateAllCurrentStatistics$.next();
      }
    );
});

export const reset = createAsyncThunk("data/reset", () => {
  if (refreshCurrentStatisticsInterval) {
    clearInterval(refreshCurrentStatisticsInterval);
    refreshCurrentStatisticsInterval = null;
  }
  if (cleanupYesterdayStatisticsInterval) {
    clearInterval(cleanupYesterdayStatisticsInterval);
    cleanupYesterdayStatisticsInterval = null;
  }
  if (refreshStatisticsTimeout) {
    clearTimeout(refreshStatisticsTimeout);
    refreshStatisticsTimeout = null;
  }
  compassQueuesSubscription?.unsubscribe();
  compassQueuesSubscription = null;
  compassUsersSubscription?.unsubscribe();
  compassUsersSubscription = null;
  compassCallsSubscription?.unsubscribe();
  compassCallsSubscription = null;
});

export const updateTodayStatistics = createAsyncThunk<
  { calls: DataState["calls"]; realtimeCalls: DataState["realtimeCalls"] },
  {
    connection: Connection;
    companyId: CompassCompany["entityId"];
    server: string;
  },
  { state: RootState }
>(
  "data/updateTodayStatistics",
  async ({ connection, companyId, server }, { getState, rejectWithValue }) => {
    let data: string[][] = [];
    try {
      const response = await wrapApiError(
        axios.get(
          `https://files.${server}/cdr/${companyId}/${getDateStr(
            new Date()
          )}/queue.csv`,
          {
            responseType: "text",
            headers: {
              Authorization: connection.rest.authHeader,
            },
          }
        )
      );
      data = parse<string[]>(response.data).data;
    } catch (e) {
      handleError(e);
      return rejectWithValue(getErrorMessage(e));
    }
    const calls: { [key: string]: CallStatistics } = {};
    data
      .slice(1, data.length - 1)
      .forEach(
        ([
          callId,
          dateString,
          tz,
          waitDuration,
          duration,
          queueId,
        ]: string[]) => {
          calls[getStatsCallId(callId, queueId)] = {
            callId,
            queueId,
            answered: !!duration,
            waitDuration: parseInt(waitDuration),
            timeStarted: new Date(`${dateString} ${tz}`).valueOf(),
          };
        }
      );

    const realtimeCalls: DataState["realtimeCalls"] = {
      ...getState().data.realtimeCalls,
    };
    Object.keys(realtimeCalls).forEach((id) => {
      if (
        calls[id] ||
        getDateStr(new Date(realtimeCalls[id].timeStarted)) !==
          getDateStr(new Date())
      ) {
        delete realtimeCalls[id];
      }
    });
    return { calls, realtimeCalls };
  }
);

const initialState: DataState = {
  todayStatistics: {},
  currentStatistics: {},
  calls: {},
  realtimeCalls: {},
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    updateQueueCurrentStatistics: {
      prepare(queue: Queue) {
        return {
          payload: {
            id: queue.id,
            statistics: getQueueStatistics(queue),
          },
        };
      },
      reducer(
        state,
        {
          payload: { id, statistics },
        }: { payload: { id: Queue["id"]; statistics: QueueDetailsStatistics } }
      ) {
        state.currentStatistics[id] = statistics;
      },
    },
    updateCurrentStatistics: {
      prepare(queues: Queue[]) {
        const currentStatistics: DataState["currentStatistics"] = {};
        queues.forEach((queue) => {
          currentStatistics[queue.id] = getQueueStatistics(queue);
        });
        return { payload: currentStatistics };
      },
      reducer(
        state,
        {
          payload: currentStatistics,
        }: { payload: DataState["currentStatistics"] }
      ) {
        Object.assign(state.currentStatistics, currentStatistics);
      },
    },
    cleanupYesterdayStatistics(state) {
      const calls: DataState["calls"] = {};
      const realtimeCalls: DataState["realtimeCalls"] = {};
      Object.values(state.calls).forEach((call) => {
        if (getDateStr(new Date(call.timeStarted)) !== getDateStr(new Date())) {
          return;
        }
        calls[call.callId] = call;
      });
      Object.values(state.realtimeCalls).forEach((call) => {
        if (getDateStr(new Date(call.timeStarted)) !== getDateStr(new Date())) {
          return;
        }
        realtimeCalls[call.callId] = call;
      });
      state.calls = calls;
      state.realtimeCalls = realtimeCalls;
      state.todayStatistics = getTodayStatistics({
        ...calls,
        ...realtimeCalls,
      });
    },
    addRealtimeCall(
      state,
      {
        payload: { call, queueId, answered },
      }: { payload: { call: Call; queueId: Queue["id"]; answered: boolean } }
    ) {
      const fullDuration =
        Math.round(+new Date() / 1000) - call.source.timeCreated;
      let duration: number | undefined = undefined;
      let waitDuration = fullDuration;
      if (call.source.timeStarted) {
        duration = Math.round(+new Date() / 1000) - call.source.timeCreated;
        waitDuration = fullDuration - duration;
      }
      const callStatistics: CallStatistics = {
        callId: call.id,
        queueId,
        answered,
        waitDuration,
        timeStarted: call.source.timeCreated * 1000,
      };
      state.todayStatistics = getTodayStatistics({
        ...state.calls,
        ...state.realtimeCalls,
        [getStatsCallId(call.id, queueId)]: callStatistics,
      });
      state.realtimeCalls[getStatsCallId(call.id, queueId)] = callStatistics;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      updateTodayStatistics.fulfilled,
      (state, { payload: { realtimeCalls, calls } }) => {
        state.calls = calls;
        state.realtimeCalls = realtimeCalls;
        state.todayStatistics = getTodayStatistics({
          ...calls,
          ...realtimeCalls,
        });
      }
    );
    builder.addCase(reset.fulfilled, () => initialState);
  },
});

export const selectTodayStatistics = ({
  data: { todayStatistics },
}: RootState) => todayStatistics;

export const selectCurrentStatistics = ({
  data: { currentStatistics },
}: RootState) => currentStatistics;

export const { addRealtimeCall, updateCurrentStatistics } = dataSlice.actions;

export default dataSlice.reducer;
