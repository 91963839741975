import * as React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Button from "src/components/Button/Button";
import Card from "src/components/Card/Card";
import { login, selectAuthStatus } from "src/store/auth";
import styles from "./Login.module.css";
import packageJSON from "../../package.json";
import logoImg from "src/assets/panel-logo-light.png";

type LoginFormValues = {
  username: string;
  password: string;
};

function Login() {
  const { register, handleSubmit, errors } = useForm<LoginFormValues>();
  const dispatch = useDispatch();
  const handleLogin = handleSubmit(async (formData) => {
    dispatch(login(formData));
  });
  const authStatus = useSelector(selectAuthStatus);
  return (
    <section className={styles.login}>
      <Card className={styles.card}>
        <div className={styles.logo}>
          <div className={styles.logoContent}>
            <img alt="Compass Panel Logo" src={logoImg} />
            <div className={styles.version}>Version {packageJSON.version}</div>
          </div>
        </div>
        <div className={styles.error}>{authStatus.loginErrorMessage}</div>
        <form onSubmit={handleLogin}>
          <div
            className={`${styles.control} ${
              !!errors.username || !!authStatus.loginErrorMessage
                ? styles["control--has-error"]
                : ""
            }`}
          >
            <label htmlFor="LoginUsername">Username</label>
            <input
              id="LoginUsername"
              ref={register({
                required: "Username is required",
              })}
              type="text"
              name="username"
              placeholder="Username"
            ></input>
            <div className={styles.controlError}>
              {errors.username?.message}
            </div>
          </div>
          <div
            className={`${styles.control} ${
              !!errors.password || !!authStatus.loginErrorMessage
                ? styles["control--has-error"]
                : ""
            }`}
          >
            <label htmlFor="LoginPassword">Password</label>
            <input
              id="LoginPassword"
              ref={register({
                required: "Password is required",
              })}
              type="password"
              name="password"
              placeholder="Password"
            ></input>
            <div className={styles.controlError}>
              {errors.password?.message}
            </div>
          </div>
          <Button
            type="submit"
            disabled={authStatus.loginInProgress}
            className={styles.btn}
          >
            Sign In
          </Button>
        </form>
      </Card>
    </section>
  );
}

export default Login;
