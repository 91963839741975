import { setup as setupData, reset as resetData } from "./data";
import { reset as resetNavigation } from "src/store/navigation";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Connection, Queue, User } from "compass.js";
import { BehaviorSubject, Subscription } from "rxjs";
import { CompassCompany, getConnectInfo } from "src/utils/api";
import { userStorage } from "src/utils/userStorage";
import { RootState } from "./rootReducer";
import { clearSettings, initializeSettings, setQueues } from "./settings";
import {
  compassDebouncePipe,
  sortByProperty,
  sortIgnoreCaseComparator,
} from "src/utils";
import { getQueueDetails } from "src/utils/queue";
import aes from "crypto-js/aes";
import encUtf8 from "crypto-js/enc-utf8";

type AuthState = {
  readonly userDisplayName?: User["name"];
  readonly company?: CompassCompany;
  readonly isAuthenticated: boolean;
  readonly loginInProgress: boolean;
  readonly loginErrorMessage?: string;
  readonly initializationInProgress: boolean;
};

enum UserPermission {
  permWrite = "permWrite",
  permRead = "permRead",
  permNone = "permNone",
}

enum AuthErrorCode {
  noPermissions = "noPermissions",
}

type LoginCredentials = {
  username: string;
  password: string;
};

const AUTH_STATE_KEY = "panel:auth:state";
const STORE_SECRET_PHRASE = "panel:phrase";
const authStorage = localStorage;

export const compassConnection$: BehaviorSubject<Connection | null> = new BehaviorSubject<Connection | null>(
  null
);
let compassQueuesSubscription: Subscription | null;

const saveLoginCredentials = (username: string, password: string) => {
  authStorage.setItem(
    AUTH_STATE_KEY,
    aes
      .encrypt(JSON.stringify({ username, password }), STORE_SECRET_PHRASE)
      .toString()
  );
};

const getLoginCredentials = (): LoginCredentials | null => {
  try {
    const authState = authStorage.getItem(AUTH_STATE_KEY);
    if (!authState) {
      throw new Error();
    }
    return JSON.parse(
      aes.decrypt(authState, STORE_SECRET_PHRASE).toString(encUtf8)
    );
  } catch {
    return null;
  }
};

const clearLoginCredentials = () => {
  authStorage.removeItem(AUTH_STATE_KEY);
};

const proceedLogin = createAsyncThunk<
  {
    userDisplayName: string;
    company: CompassCompany;
  },
  LoginCredentials,
  { state: RootState }
>(
  "auth/proceedLogin",
  async ({ username, password }, { dispatch, rejectWithValue }) => {
    const connectInfo = await getConnectInfo(username, password);
    const connection = new Connection(connectInfo.server);
    await connection.connect(connectInfo.jid, password);
    const { name: userDisplayName } = connection.model.getUserForJid(
      connectInfo.jid
    );
    const company: CompassCompany = await connection.rest.getMyCompany();
    const user = connection.model.getUserForJid(connectInfo.jid);
    const {
      value: companyPermission,
    }: { value: UserPermission } = await connection.rest.get(
      `${connection.rest.getUrlForObject(
        "user",
        parseInt(user.id, 10)
      )}/permission?targetEntity=${company.self}`
    );
    if (companyPermission !== UserPermission.permWrite) {
      return rejectWithValue({ type: AuthErrorCode.noPermissions });
    }
    userStorage.init(connectInfo.jid);
    await dispatch(initializeSettings());
    compassConnection$.next(connection);

    const getQueues = () => {
      return sortByProperty<Queue, Queue["name"]>(
        Object.values(connection.model.queues),
        "name",
        sortIgnoreCaseComparator
      ).map((queue) => getQueueDetails(queue));
    };
    dispatch(
      setupData({
        connection,
        companyId: company.entityId,
        server: connectInfo.server,
      })
    );
    dispatch(setQueues(getQueues()));
    compassQueuesSubscription = connection.model.queuesObservable
      .pipe(compassDebouncePipe(500))
      .subscribe(() => {
        dispatch(setQueues(getQueues()));
      });
    saveLoginCredentials(username, password);
    return {
      userDisplayName,
      company,
    };
  }
);

export const login = createAsyncThunk<
  void,
  LoginCredentials,
  { state: RootState }
>("auth/login", async (credentials, { dispatch, rejectWithValue }) => {
  const response = await dispatch(proceedLogin(credentials));
  if (response.type === proceedLogin.rejected.type) {
    let message = `We don't recognize your username and/or password.`;
    if (
      response.payload &&
      (response.payload as any).type === AuthErrorCode.noPermissions
    ) {
      message = "Your account doesn't have enough rights.";
    }
    rejectWithValue(message);
    return;
  }
});

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch }) => {
    clearLoginCredentials();
    userStorage.reset();
    compassConnection$.getValue()?.disconnect();
    compassConnection$.next(null);
    compassQueuesSubscription?.unsubscribe();
    compassQueuesSubscription = null;
    dispatch(clearSettings());
    dispatch(resetData());
    dispatch(resetNavigation());
    dispatch(authSlice.actions.cleanUserData());
  }
);

export const authInitialize = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("auth/authInitialize", async (_, { rejectWithValue, dispatch }) => {
  const loginCredentials = getLoginCredentials();
  if (!loginCredentials) {
    return;
  }
  const { username, password } = loginCredentials;
  const response = await dispatch(proceedLogin({ username, password }));
  if (response.type === proceedLogin.rejected.type) {
    clearLoginCredentials();
    rejectWithValue(null);
  }
});

const initialState: AuthState = {
  isAuthenticated: false,
  loginInProgress: false,
  initializationInProgress: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    cleanUserData() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loginInProgress = true;
      state.loginErrorMessage = undefined;
    });
    builder.addCase(login.fulfilled, (state) => {
      state.loginInProgress = false;
    });
    builder.addCase(login.rejected, (state, { payload: errorMessage }) => {
      state.loginInProgress = false;
      state.loginErrorMessage = errorMessage as string;
    });
    builder.addCase(authInitialize.pending, (state) => {
      state.initializationInProgress = true;
    });
    builder.addCase(authInitialize.fulfilled, (state) => {
      state.initializationInProgress = false;
    });
    builder.addCase(authInitialize.rejected, (state) => {
      state.initializationInProgress = false;
    });
    builder.addCase(
      proceedLogin.fulfilled,
      (state, { payload: { userDisplayName, company } }) => {
        state.userDisplayName = userDisplayName;
        state.company = company;
        state.isAuthenticated = true;
      }
    );
  },
});

export const selectAuthStatus = ({
  auth: {
    isAuthenticated,
    loginInProgress,
    initializationInProgress,
    loginErrorMessage,
  },
}: RootState) => ({
  isAuthenticated,
  loginInProgress,
  initializationInProgress,
  loginErrorMessage,
});

export const selectAuthUserName = ({ auth: { userDisplayName } }: RootState) =>
  userDisplayName;

export default authSlice.reducer;
